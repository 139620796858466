import { generatePath } from 'react-router-dom';

// keepOriginalHost is a list of hosts where the API is exposed on the same host.
const keepOriginalHost = [
  'feature-flags-dev.adidas.com',
  'feature-flags.adidas.com',
  'localhost',
];

function getApiHost(): string {
  const { hostname: originalHost, protocol } = window.location;
  if (keepOriginalHost.includes(originalHost)) {
    return '';
  }
  const newHost = originalHost.replace('feature-flags-ui', 'feature-flags-api');
  return `${protocol}//${newHost}`;
}

export function getPath(
  path: string,
  params?: Record<string, string | undefined>
) {
  const interpolatedPath = generatePath(path, params);
  const fullPath = getApiHost() + '/api' + interpolatedPath;
  return fullPath;
}
